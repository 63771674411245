@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}

